import { Input, InputProps } from "@nextui-org/react";
import React, { useCallback, useMemo, useState } from "react";
import { PasswordToggle } from "../../Atoms/PasswordToggle";
import OtpInput from "react-otp-input";
import { motion, AnimatePresence } from "framer-motion";
import { Check } from "lucide-react";

interface EmailInputProps {
  email: string;
  setEmail: (value: string) => void;
}

interface PasswordInputProps {
  password: string;
  setPassword: (value: string) => void;
  isInvalid?: boolean;
  errorMessage?: string;
  label?: string;
}

interface PhoneNumberInputProps extends Omit<InputProps, "value" | "onChange"> {
  value: string;
  onChange: (value: string) => void;
}
interface OTPInputProps {
  verificationCode: string;
  setVerificationCode: React.Dispatch<React.SetStateAction<string>>;
}

const counterVariants = {
  initial: { scale: 0.8, opacity: 0 },
  animate: { scale: 1, opacity: 1 },
  exit: { scale: 0.8, opacity: 0 },
};

const inputContainerVariants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.15,
    },
  },
};

const inputItemVariants = {
  initial: { scale: 0.8, opacity: 0 },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 20,
    },
  },
};

const stripNonNumeric = (str: string) => str.replace(/\D/g, "");

const formatPhoneNumber = (numericValue: string) => {
  const cleaned = stripNonNumeric(numericValue);

  // Handle empty or very short inputs
  if (cleaned.length < 2) return cleaned;

  // Add area code parentheses
  let formatted = `(${cleaned.slice(0, 2)}`;

  if (cleaned.length < 3) return formatted;
  formatted += ") ";

  // Handle 8 or 9 digit numbers differently
  if (cleaned.length <= 6) {
    // Add first part of the number
    formatted += cleaned.slice(2);
  } else if (cleaned.length <= 10) {
    // 8-digit format
    formatted += `${cleaned.slice(2, 6)}-${cleaned.slice(6)}`;
  } else {
    // 9-digit format
    formatted += `${cleaned.slice(2, 7)}-${cleaned.slice(7, 11)}`;
  }

  return formatted;
};

export const EmailInput: React.FC<EmailInputProps> = ({ email, setEmail }) => {
  const validateEmail = (value: string) =>
    value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);

  const isInvalid = useMemo(() => {
    return email !== "" && !validateEmail(email);
  }, [email]);

  return (
    <Input
      size="lg"
      type="email"
      label="E-mail"
      variant="faded"
      isInvalid={isInvalid}
      color={isInvalid ? "danger" : "default"}
      errorMessage="Digite um e-mail válido"
      value={email}
      onValueChange={setEmail}
      isRequired
    />
  );
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  password,
  setPassword,
  isInvalid,
  errorMessage,
  label = "Senha",
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Input
      size="lg"
      type={isVisible ? "text" : "password"}
      endContent={
        <PasswordToggle
          isVisible={isVisible}
          onClick={() => setIsVisible(!isVisible)}
        />
      }
      label={label}
      variant="faded"
      value={password}
      onValueChange={setPassword}
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      isRequired
    />
  );
};

export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value,
  onChange,
  ...props
}) => {
  // Keep track of the masked value for display
  const [maskedValue, setMaskedValue] = useState(() =>
    formatPhoneNumber(value)
  );

  // Handle input changes
  const handleChange = useCallback(
    (inputValue: string) => {
      const numericValue = stripNonNumeric(inputValue);

      // Limit to 11 digits (2 for area code + 9 for number)
      if (numericValue.length <= 11) {
        const formatted = formatPhoneNumber(numericValue);
        setMaskedValue(formatted);
        onChange(numericValue); // Pass only numeric value to parent
      }
    },
    [onChange]
  );

  return (
    <Input
      {...props}
      value={maskedValue}
      onValueChange={handleChange}
      placeholder="(XX) XXXXX-XXXX"
      type="tel"
      maxLength={15} // Max length of formatted number: (11) 91111-1111
      autoComplete="tel-national"
    />
  );
};

export const OTPInput: React.FC<OTPInputProps> = ({
  verificationCode,
  setVerificationCode,
}) => {
  const isComplete = verificationCode.length === 6;

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between mb-2">
        <div>
          <p className="text-sm font-medium text-default-700">
            Código de Verificação
          </p>
          <p className="text-xs text-default-500">
            Digite o código de 6 dígitos enviado por SMS
          </p>
        </div>
        <AnimatePresence mode="wait">
          {isComplete ? (
            <motion.div
              key="complete"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={counterVariants}
              className="bg-success/20 text-success px-2 py-1 rounded-full flex items-center gap-1"
            >
              <Check size={12} />
              <span className="text-xs font-medium">6/6</span>
            </motion.div>
          ) : (
            <motion.div
              key="counting"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={counterVariants}
              className="flex items-center gap-1"
            >
              <div className="w-4 h-4 rounded-full bg-primary/20 flex items-center justify-center">
                <span className="text-[10px] text-primary font-medium">
                  {verificationCode.length}
                </span>
              </div>
              <span className="text-xs text-default-500">/</span>
              <span className="text-xs text-default-500">6</span>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <motion.div
        variants={inputContainerVariants}
        initial="initial"
        animate="animate"
      >
        <OtpInput
          value={verificationCode}
          onChange={setVerificationCode}
          numInputs={6}
          inputType="tel"
          shouldAutoFocus
          containerStyle="gap-1 justify-center"
          renderInput={(props: any) => (
            <motion.div className="relative" variants={inputItemVariants}>
              <Input
                {...props}
                classNames={{
                  input: "text-center text-lg font-medium",
                  inputWrapper: `shadow-sm hover:shadow-md transition-all duration-200 
                    ${
                      props.value
                        ? "border-primary group-data-[focus=true]:border-primary data-[hover=true]:border-primary"
                        : "border-default-200"
                    }
                    ${
                      isComplete
                        ? "border-success group-data-[focus=true]:border-success data-[hover=true]:border-success"
                        : ""
                    }`,
                }}
                size="lg"
                variant="bordered"
              />
              {!props.value && (
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                  <div className="w-2 h-2 rounded-full bg-default-200" />
                </div>
              )}
            </motion.div>
          )}
        />
      </motion.div>
    </div>
  );
};
