import { Button, Divider, Link } from "@nextui-org/react";
import { useState, useMemo } from "react";
import { useAuth } from "../../../contexts/auth.context";
import { toast } from "react-toastify";
import { AuthForm } from "../../Organisms/AuthForm";
import { EmailInput, PasswordInput } from "../../Molecules/Inputs/index.tsx";
import { GoogleIcon } from "../../../assets/GoogleIcon.tsx";

export const SignUp: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { handleSignUp, handleGoogleSignIn } = useAuth();

  const validateEmail = (value: string) =>
    value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i);

  const isEmailInvalid = useMemo(() => {
    return email !== "" && !validateEmail(email);
  }, [email]);

  const arePasswordsInvalid = useMemo(() => {
    return (
      password !== "" && confirmPassword !== "" && password !== confirmPassword
    );
  }, [password, confirmPassword]);

  const isFormValid = useMemo(() => {
    return (
      email !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      !isEmailInvalid &&
      !arePasswordsInvalid
    );
  }, [email, password, confirmPassword, isEmailInvalid, arePasswordsInvalid]);

  const signUp = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isFormValid) return;

    setIsLoading(true);
    try {
      await handleSignUp({ email, password, role: "customer" });

      toast.success("Conta criada com sucesso", {
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error: any) {
      switch (error.message) {
        case "FirebaseError: Firebase: Error (auth/invalid-email).":
          toast.error("O e-mail digitado é inválido. Tente novamente.", {
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });

          break;
        case "FirebaseError: Firebase: Error (auth/email-already-in-use).":
          toast.error("Já existe uma conta com esse e-mail.", {
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });

          break;
        default:
          toast.error("Erro ao criar conta. Por favor, tente novamente ", {
            type: "error",
            isLoading: false,
            autoClose: 2000,
          });

          console.log(error.message);
          break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthForm
      title="Seja bem-vindo(a)"
      subtitle="Preencha as informações para fazer parte do melhor app de medicina"
      onSubmit={signUp}
      isFormValid={isFormValid}
      isLoading={isLoading}
      submitText="Criar Conta"
      links={
        <div className="flex justify-center mt-1">
          <Link className="cursor-pointer text-primary-900" href="/login">
            Já tenho uma conta
          </Link>
        </div>
      }
    >
      <Button
        color="default"
        variant="bordered"
        size="lg"
        onPress={handleGoogleSignIn}
        className="bg-white border-default-200"
      >
        <GoogleIcon /> Criar Conta com Google
      </Button>
      <Divider
        orientation="horizontal"
        className="bg-default-300 rounded-full h-0.5"
      />
      <EmailInput email={email} setEmail={setEmail} />
      <PasswordInput password={password} setPassword={setPassword} />
      <PasswordInput
        password={confirmPassword}
        setPassword={setConfirmPassword}
        label="Confirme sua senha"
        isInvalid={arePasswordsInvalid}
        errorMessage="As senhas não são iguais"
      />
    </AuthForm>
  );
};
