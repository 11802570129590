import React from "react";
import { Share } from "lucide-react";
import { useInstallPrompt } from "../../../hooks/useInstallPrompt";
import { Prompt } from "../../Atoms/Prompt";
import { Button } from "@nextui-org/react";

export const InstallPrompt: React.FC = () => {
  const { showPrompt, handleInstall, handleDismiss, isIOS, buttonColor } =
    useInstallPrompt();

  return (
    <Prompt
      show={showPrompt}
      onDismiss={handleDismiss}
      icon={
        <img
          src="/images/icons/icon.png"
          className="w-12 h-12 text-primary rounded-medium"
        />
      }
      message={
        isIOS ? (
          <span className="flex-grow text-sm sm:text-base">
            Adicione o atalho do Zip Saúde. Toque em{" "}
            <Share className="inline-block w-5 h-5 mx-1" /> na barra do seu
            dispositivo e depois em "Adicionar à Tela de Início"
          </span>
        ) : (
          <span className="flex-grow text-sm sm:text-base">
            Adicione o Zip Saúde como aplicativo para ter uma melhor experiência
          </span>
        )
      }
      confirmButton={
        !isIOS && (
          <Button
            color={buttonColor}
            isDisabled={buttonColor === "success"}
            onPress={handleInstall}
            size="sm"
          >
            Adicionar App
          </Button>
        )
      }
      cancelText="Adicionar Depois"
    />
  );
};
