// services/phoneVerification.ts
import { doc, updateDoc } from "firebase/firestore";
import { fireStoreDb } from "../firebase/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

interface VerificationAttempt {
  codeHash: ArrayBuffer;
  salt: Uint8Array;
  createdAt: number;
  attempts: number;
}
interface SMSFunctionResponse {
  success: boolean;
  message: string;
}

class PhoneVerificationService {
  private static readonly CODE_EXPIRY = 5 * 60 * 1000; // 5 minutes
  private static readonly MAX_ATTEMPTS = 5;
  private verificationStore: Map<string, VerificationAttempt>;
  private rateLimiter: Map<string, number[]>;
  private functions: ReturnType<typeof getFunctions>;

  constructor() {
    this.verificationStore = new Map();
    this.rateLimiter = new Map();
    this.functions = getFunctions();
  }

  private formatPhoneNumber(phoneNumber: string): string {
    // Remove any non-digit characters
    const cleaned = phoneNumber.replace(/\D/g, "");

    // Validate phone number format
    if (cleaned.length < 10 || cleaned.length > 11) {
      throw new Error("Formato de número de telefone inválido");
    }

    // Ensure it starts with Brazil country code
    return cleaned.startsWith("55") ? cleaned : `55${cleaned}`;
  }

  private isCodeExpired(createdAt: number): boolean {
    return Date.now() - createdAt > PhoneVerificationService.CODE_EXPIRY;
  }

  private getErrorMessage(errorCode: string): string {
    const errorMessages: Record<string, string> = {
      invalid_token: "Erro de autenticação com o serviço de SMS",
      invalid_number: "Número de telefone inválido",
      message_empty: "Mensagem vazia não permitida",
      insufficient_credits: "Serviço temporariamente indisponível",
      service_error: "Erro no serviço de SMS. Tente novamente mais tarde.",
      rate_limit_exceeded: "Muitas tentativas. Tente novamente mais tarde.",
      expired_code: "Código expirado. Solicite um novo.",
      max_attempts_exceeded: "Número máximo de tentativas excedido",
      invalid_code: "Código inválido",
      user_not_found: "Usuário não encontrado",
      database_error: "Erro ao atualizar banco de dados",
    };

    return errorMessages[errorCode] || "Erro inesperado. Tente novamente.";
  }

  private generateOTP(): string {
    const array = new Uint32Array(1);
    crypto.getRandomValues(array);
    return String(array[0] % 1000000).padStart(6, "0");
  }

  private async hashCode(code: string, salt: Uint8Array): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const codeData = encoder.encode(code);

    const combinedData = new Uint8Array(salt.length + codeData.length);
    combinedData.set(salt);
    combinedData.set(codeData, salt.length);

    return await crypto.subtle.digest("SHA-256", combinedData);
  }

  private async compareHash(a: ArrayBuffer, b: ArrayBuffer): Promise<boolean> {
    const aArray = new Uint8Array(a);
    const bArray = new Uint8Array(b);

    if (aArray.length !== bArray.length) return false;

    let result = 0;
    for (let i = 0; i < aArray.length; i++) {
      result |= aArray[i] ^ bArray[i];
    }
    return result === 0;
  }

  private generateSalt(): Uint8Array {
    const salt = new Uint8Array(16);
    crypto.getRandomValues(salt);
    return salt;
  }

  private isRateLimited(phoneNumber: string): boolean {
    const now = Date.now();
    const attempts = this.rateLimiter.get(phoneNumber) || [];
    const recentAttempts = attempts.filter((time) => now - time < 3600000);
    this.rateLimiter.set(phoneNumber, recentAttempts);
    return recentAttempts.length >= 10;
  }

  private async sendSMS(phoneNumber: string, code: string): Promise<void> {
    const formattedPhone = this.formatPhoneNumber(phoneNumber);
    const message = `Zip Saúde - Seu código de verificação é: ${code}. Digite este código no app para continuar. Válido por 5 minutos.`;

    try {
      const sendSMSFunction = httpsCallable<
        { phoneNumber: string; message: string },
        SMSFunctionResponse
      >(this.functions, "sendSMS");

      const result = await sendSMSFunction({
        phoneNumber: formattedPhone,
        message: message,
      });

      if (!result.data.success) {
        throw new Error(this.getErrorMessage(result.data.message));
      }
    } catch (error) {
      console.error("SMS service error:", error);
      throw new Error(this.getErrorMessage("service_error"));
    }
  }

  async sendVerificationCode(phoneNumber: string): Promise<void> {
    if (this.isRateLimited(phoneNumber)) {
      throw new Error(this.getErrorMessage("rate_limit_exceeded"));
    }

    const attempts = this.rateLimiter.get(phoneNumber) || [];
    attempts.push(Date.now());
    this.rateLimiter.set(phoneNumber, attempts);

    const existingAttempt = this.verificationStore.get(phoneNumber);
    if (existingAttempt && !this.isCodeExpired(existingAttempt.createdAt)) {
      throw new Error("Aguarde 5 minutos antes de solicitar um novo código");
    }

    const code = this.generateOTP();
    const salt = this.generateSalt();
    const codeHash = await this.hashCode(code, salt);

    try {
      await this.sendSMS(phoneNumber, code);

      this.verificationStore.set(phoneNumber, {
        codeHash,
        salt,
        createdAt: Date.now(),
        attempts: 0,
      });
    } catch (error) {
      this.verificationStore.delete(phoneNumber);
      throw error;
    }
  }

  async verifyCode(
    phoneNumber: string,
    code: string,
    userId: string
  ): Promise<boolean> {
    const verificationAttempt = this.verificationStore.get(phoneNumber);

    if (!verificationAttempt) {
      throw new Error(this.getErrorMessage("invalid_code"));
    }

    if (this.isCodeExpired(verificationAttempt.createdAt)) {
      this.verificationStore.delete(phoneNumber);
      throw new Error(this.getErrorMessage("expired_code"));
    }

    if (verificationAttempt.attempts >= PhoneVerificationService.MAX_ATTEMPTS) {
      this.verificationStore.delete(phoneNumber);
      throw new Error(this.getErrorMessage("max_attempts_exceeded"));
    }

    verificationAttempt.attempts++;

    const hashedInput = await this.hashCode(code, verificationAttempt.salt);
    if (!(await this.compareHash(hashedInput, verificationAttempt.codeHash))) {
      const remainingAttempts =
        PhoneVerificationService.MAX_ATTEMPTS - verificationAttempt.attempts;
      throw new Error(
        `Código inválido. ${remainingAttempts} tentativa(s) restante(s).`
      );
    }

    try {
      await updateDoc(doc(fireStoreDb, "users", userId), {
        phoneNumber: `+55${phoneNumber}`,
        phoneConfirmed: true,
        verifiedAt: new Date().toISOString(),
      });

      this.verificationStore.delete(phoneNumber);
      return true;
    } catch (error) {
      console.error("Firestore update error:", error);
      throw new Error(this.getErrorMessage("database_error"));
    }
  }
}

export const phoneVerificationService = new PhoneVerificationService();
