import React from "react";
import { GradientIcon } from "../../Atoms/GradientIcon";

export const Loading: React.FC = () => {
  return (
    <main
      className="flex flex-col justify-center items-center min-h-svh gap-4 w-full bg-neutral-100 dark:bg-[#0D031E]"
      style={{
        backgroundImage: "var(--bg-image)",
        backgroundRepeat: "repeat",
        backgroundSize: "400px 400px",
      }}
    >
      <div className="absolute inset-0 bg-neutral-100 bg-opacity-40 dark:bg-[#0D031E] !dark:bg-opacity-100"></div>
      <div className="relative z-10 flex flex-col justify-center gap-4 items-center w-full">
        <GradientIcon className="w-32 h-32 animate-pulse" />
      </div>
      <style>
        {`
          :root {
            --bg-image: url("/images/light-pattern.png");
          }
          @media (prefers-color-scheme: dark) {
            :root {
              --bg-image: url("/images/dark-pattern.png");
            }
          }
        `}
      </style>
    </main>
  );
};
