import { doc, setDoc } from "firebase/firestore";
import { auth, fireStoreDb, messaging } from "../firebase/firebase";
import { getToken, onMessage } from "firebase/messaging";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";

const VAPID_KEY =
  "BF24nzWTu3GM0WbFPXJAwtx85P4kXImzpPRuQLabJuFj8whv75VbXejjVTtbEpAWGFg4k_3pU3kZEqUJEpazsVY";

class NotificationService {
  private initialized = false;
  private navigate: NavigateFunction | null = null;

  async initialize(navigate: NavigateFunction) {
    if (this.initialized) return;
    this.navigate = navigate;

    if (this.isPWA()) {
      console.log(
        "Running as PWA, waiting for user interaction to request notification permission"
      );
    } else {
      await this.setupNotifications();
    }

    this.setupForegroundMessaging();
    this.initialized = true;
  }

  isPWA() {
    return (
      window.matchMedia("(display-mode: standalone)").matches ||
      (window.navigator as any).standalone
    );
  }

  async requestNotificationPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      await this.getAndStoreToken();
    }
    return permission;
  }

  private async getAndStoreToken() {
    try {
      const token = await getToken(messaging, { vapidKey: VAPID_KEY });
      if (token) {
        await this.storeUserToken(token);
      }
    } catch (error) {
      console.error("An error occurred while retrieving token:", error);
    }
  }

  private async storeUserToken(token: string) {
    const user = auth.currentUser;
    if (user) {
      await setDoc(doc(fireStoreDb, "userTokens", user.uid), { token });
    }
  }

  private async setupNotifications() {
    const permission = await this.requestNotificationPermission();
    if (permission !== "granted") {
      console.log("Notification permission not granted");
    }
  }
  private setupForegroundMessaging() {
    onMessage(messaging, (payload: any) => {
      const { body, imageUrl, URL } = payload.data;

      toast.info(
        <div
          onClick={() => this.handleNotificationClick(URL)}
          className="flex items-start gap-2 cursor-pointer"
        >
          <img
            src={imageUrl || "/images/icons/icon.png"}
            alt="Notification Icon"
            className="w-12 h-12 rounded-medium"
          />
          <p>{body}</p>
        </div>,
        {
          autoClose: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: false,
        }
      );
    });
  }

  private handleNotificationClick(url: string) {
    if (this.navigate) {
      this.navigate(url);
    } else {
      console.error("Navigation function not set");
    }
  }
}

export const notificationService = new NotificationService();
