import React from "react";
import { Select, SelectItem } from "@nextui-org/react";
import { Schedule } from "../../../models/Schedule";

interface ExamSelectionProps {
  selectedExam: string | null;
  offeredExams: Schedule["exams"];
  onExamChange: (examId: string | null) => void;
}

export const ExamSelection: React.FC<ExamSelectionProps> = ({
  selectedExam,
  offeredExams,
  onExamChange,
}) => {
  return (
    <div className="flex flex-col w-full gap-2 bg-white rounded-large">
      <div className="w-full bg-default-100 rounded-medium p-2">
        <h2 className="text-default-700 font-semibold text-sm">Exame</h2>
      </div>
      {offeredExams && Object.keys(offeredExams).length > 0 ? (
        <Select
          size="lg"
          radius="md"
          variant="bordered"
          label="Selecione um exame"
          selectedKeys={selectedExam ? [selectedExam] : []}
          onSelectionChange={(keys) => {
            const selected = Array.from(keys as Set<string>)[0] || null;
            onExamChange(selected);
          }}
          classNames={{
            trigger: "h-12",
            value: "text-base",
            listbox: "min-w-[240px]",
          }}
          renderValue={(items) => {
            const selected = items[0];
            if (!selected || !offeredExams[selected.key as string]) return null;
            const exam = offeredExams[selected.key as string];
            return (
              <div className="flex justify-between items-center gap-2">
                <span>{exam.name}</span>
                {exam.price && (
                  <span className="text-success-600 font-semibold">
                    {exam.price}
                  </span>
                )}
              </div>
            );
          }}
        >
          {Object.entries(offeredExams).map(([examId, examDetails]) => (
            <SelectItem
              key={examId}
              textValue={examDetails.name || ""}
              color="secondary"
              variant="flat"
            >
              <div className="flex justify-between items-center">
                <span>{examDetails.name}</span>
                {examDetails.price && (
                  <span className="text-success-600 font-semibold">
                    {examDetails.price}
                  </span>
                )}
              </div>
            </SelectItem>
          ))}
        </Select>
      ) : (
        <p className="text-sm text-danger-600">
          Nenhum exame disponível no momento.
        </p>
      )}
    </div>
  );
};
