import { useQuery } from "@tanstack/react-query";
import {
  collection,
  endAt,
  getDocs,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { Appointment, AppointmentStatus } from "../../models/Appointment";
import { Dayjs } from "dayjs";
import { DateUtils } from "../../utils/dateUtils";

interface UseAppointmentsQueryParams {
  clinicId?: string;
  providerId?: string;
  start?: Dayjs;
  end?: Dayjs;
}

export const useAppointmentsQuery = ({
  clinicId,
  providerId,
  start,
  end,
}: UseAppointmentsQueryParams) => {
  return useQuery<Appointment[], Error>({
    queryKey: ["appointments", { clinicId, providerId, start, end }],
    queryFn: async () => {
      try {
        const appointmentsRef = collection(fireStoreDb, "appointments");
        let q;

        if (clinicId) {
          q = query(
            appointmentsRef,
            where("clinicId", "==", clinicId),
            where("status", "in", [
              AppointmentStatus.CONFIRMED,
              AppointmentStatus.WAITING_PROVIDER,
            ]),
            orderBy("option1", "asc"),
            startAt(DateUtils.queryToDBFormat(start as Dayjs, "UTC")),
            endAt(DateUtils.queryToDBFormat(end as Dayjs, "UTC"))
          );
        } else if (providerId) {
          q = query(
            appointmentsRef,
            where("providerId", "==", providerId),
            where("status", "in", [
              AppointmentStatus.CONFIRMED,
              AppointmentStatus.WAITING_PROVIDER,
            ]),
            orderBy("option1", "asc"),
            startAt(DateUtils.queryToDBFormat(start as Dayjs, "UTC")),
            endAt(DateUtils.queryToDBFormat(end as Dayjs, "UTC"))
          );
        } else {
          throw new Error("Either clinicId or providerId must be provided");
        }

        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(
          (doc) =>
            ({
              ...doc.data(),
              id: doc.id,
            } as Appointment)
        );
      } catch (error) {
        console.log(error);

        throw error;
      }
    },
    enabled: true, // Always enable the query
  });
};
