import { Button, ButtonGroup } from "@nextui-org/react";
import { CalendarDate, today, parseDate } from "@internationalized/date";

const DatePresetButtonGroup = ({
  onSelectPreset,
}: {
  onSelectPreset: ({
    start,
    end,
  }: {
    start: CalendarDate;
    end: CalendarDate;
  }) => void;
}) => {
  const handlePresetClick = (preset: string) => {
    const now = today("America/Sao_Paulo");
    let start, end;

    switch (preset) {
      case "last7ToNext7":
        start = now.subtract({ days: 7 });
        end = now.add({ days: 7 });
        break;
      case "last14":
        start = now.subtract({ days: 14 });
        end = now;
        break;
      case "thisMonth":
        start = parseDate(now.toString().slice(0, 7) + "-01");
        end = start.add({ months: 1 }).subtract({ days: 1 });
        break;
      default:
        start = now;
        end = now;
    }

    onSelectPreset({ start, end });
  };

  return (
    <ButtonGroup
      size="sm"
      radius="md"
      variant="bordered"
      color="default"
      className="px-2 w-full pt-2 bg-white"
    >
      <Button onPress={() => handlePresetClick("last7ToNext7")}>
        <span className="block md:hidden">±7 dias</span>
        <span className="hidden md:block">±7 dias</span>
      </Button>
      <Button onPress={() => handlePresetClick("last14")}>
        <span className="block md:hidden">Últimos 14 dias</span>
        <span className="hidden md:block">Últimos 14 dias</span>
      </Button>
      <Button onPress={() => handlePresetClick("thisMonth")}>
        <span className="block md:hidden">Este Mês</span>
        <span className="hidden md:block">Este mês</span>
      </Button>
    </ButtonGroup>
  );
};

export default DatePresetButtonGroup;
