import { Button, Divider, Link } from "@nextui-org/react";
import { useState } from "react";
import { useAuth } from "../../../contexts/auth.context";
import { toast } from "react-toastify";
import { AuthForm } from "../../Organisms/AuthForm";
import { EmailInput, PasswordInput } from "../../Molecules/Inputs/index.tsx";
import { GoogleIcon } from "../../../assets/GoogleIcon.tsx";

export const SignIn: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleSignIn, handleGoogleSignIn } = useAuth();

  const isFormValid = email !== "" && password !== "";

  const signIn = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      await handleSignIn({ email, password });
    } catch (error: any) {
      if (
        error.message ===
        "FirebaseError: Firebase: Error (auth/invalid-credential)."
      ) {
        toast.error("Credenciais incorretas, tente novamente.", {
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      } else {
        toast.error("Ocorreu um erro, tente novamente.", {
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
      }
      console.log("the error was" + error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthForm
      title="Bem-vindo(a) de volta"
      subtitle="Preencha os seus dados para encontrar o profissional da saúde certo"
      onSubmit={signIn}
      isFormValid={isFormValid}
      isLoading={isLoading}
      submitText="Fazer Login"
      links={
        <div className="flex justify-between mx-0.5">
          <Link
            className="cursor-pointer text-primary-900"
            href="/reset-password"
          >
            Esqueci a senha
          </Link>
          <Link className="cursor-pointer text-primary-900" href="/sign-up">
            Criar conta
          </Link>
        </div>
      }
    >
      <Button
        color="default"
        variant="bordered"
        size="lg"
        onPress={handleGoogleSignIn}
        className="bg-white border-default-200"
      >
        <GoogleIcon /> Fazer Login com Google
      </Button>
      <Divider
        orientation="horizontal"
        className="bg-default-300 rounded-full h-0.5"
      />
      <EmailInput email={email} setEmail={setEmail} />
      <PasswordInput password={password} setPassword={setPassword} />
    </AuthForm>
  );
};
