import { Link } from "@nextui-org/react";
import { useState, useMemo } from "react";
import { useAuth } from "../../../contexts/auth.context";
import { toast } from "react-toastify";
import { AuthForm } from "../../Organisms/AuthForm";
import { EmailInput } from "../../Molecules/Inputs/index.tsx";

export const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const { handleResetPassword } = useAuth();

  const isFormValid = useMemo(() => {
    return email !== "";
  }, [email]);

  const resetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await handleResetPassword(email);
      setIsDisabled(true);

      toast.success("E-mail enviado com sucesso", {
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
    } catch (error) {
      toast.error("Ocorreu um erro, tente novamente.", {
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
      console.log("the error was" + error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthForm
      title="Redefina sua senha"
      subtitle="Preencha o seu e-mail, vamos te enviar um link de redefinição"
      onSubmit={resetPassword}
      isFormValid={isFormValid && !isDisabled}
      isLoading={isLoading}
      submitText="Enviar Link de Redefinição"
      links={
        <div className="flex justify-center mt-1">
          <Link className="cursor-pointer text-primary-900" href="/login">
            Já sei a minha senha
          </Link>
        </div>
      }
    >
      <EmailInput email={email} setEmail={setEmail} />
    </AuthForm>
  );
};
