import { Building2, Clock, MapPin, Phone } from "lucide-react";
import { BookingInfo } from "../../Atoms/BookingInfo";

export const ContactInfoGrid = ({
  phoneNumber,
  name,
  address,
  timezone,
  userTimezone,
}: {
  phoneNumber: string;
  name: string;
  address: string;
  timezone: string;
  userTimezone: string;
}) => (
  <div className="grid max-md:grid-cols-1 grid-cols-2 gap-1">
    <BookingInfo
      icon={Phone}
      text={phoneNumber}
      bgColor="bg-warning-50/50"
      textColor="text-warning-900"
    />
    <BookingInfo
      icon={Building2}
      text={name}
      bgColor="bg-primary-50/50"
      textColor="text-primary-900"
    />
    <BookingInfo
      icon={MapPin}
      text={address}
      bgColor="bg-secondary-50/50"
      textColor="text-secondary-900"
      className="col-span-2"
    />
    {timezone !== userTimezone && (
      <BookingInfo
        icon={Clock}
        text={`Fuso-horário: ${timezone}`}
        bgColor="bg-danger-50/50"
        textColor="text-danger-900"
        className="col-span-2"
      />
    )}
  </div>
);
