import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Autocomplete,
  AutocompleteItem,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
} from "@nextui-org/react";
import {
  PaymentMethod,
  PAYMENT_METHOD_LABELS,
  PaymentMethodIcon,
} from "../../../models/PaymentMethod";

export const ModalitySelector = ({
  modality,
  setModality,
  healthInsurances,
  paymentMethods,
  setSelectedHealthInsurance,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  selectedMedicalService,
  schedules,
}: {
  modality: "private" | "healthInsurance";
  setModality: (modality: "private" | "healthInsurance") => void;
  healthInsurances: string[];
  paymentMethods: PaymentMethod[];
  setSelectedHealthInsurance: (
    value: React.SetStateAction<string | null>
  ) => void;
  selectedPaymentMethod: PaymentMethod | null;
  setSelectedPaymentMethod: (
    value: React.SetStateAction<PaymentMethod | null>
  ) => void;
  selectedMedicalService: string | null;
  schedules?: {
    services?: {
      [serviceId: string]: {
        acceptsHealthInsurances?: boolean | null;
        acceptedHealthInsurances?: string[] | null;
      };
    };

    exams?: {
      [examId: string]: {
        acceptsHealthInsurances?: boolean | null;
        acceptedHealthInsurances?: string[] | null;
      };
    };
  };
}) => {
  const containerVariants = {
    hidden: { opacity: 0.8 },
    visible: {
      opacity: 1,
      transition: { duration: 0.3 },
    },
  };

  const contentVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: {
      opacity: 1,
      height: "auto",
      transition: {
        opacity: { duration: 0.2 },
        height: { duration: 0.2, type: "spring", stiffness: 300, damping: 30 },
      },
    },
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        opacity: { duration: 0.1 },
        height: { duration: 0.1, type: "spring", stiffness: 300, damping: 30 },
      },
    },
  };

  const acceptsHealthInsurances = selectedMedicalService
    ? schedules?.services?.[selectedMedicalService]?.acceptsHealthInsurances ||
      schedules?.exams?.[selectedMedicalService]?.acceptsHealthInsurances
    : false;

  return (
    <motion.div
      className="flex flex-col gap-2"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {acceptsHealthInsurances && (
        <RadioGroup
          orientation="horizontal"
          value={modality}
          onValueChange={(value) =>
            setModality(value as "private" | "healthInsurance")
          }
          className="px-0.5"
          classNames={{ label: "text-default-900 font-semibold" }}
        >
          <Radio value="private">Particular</Radio>
          <Radio value="healthInsurance">Plano de Saúde</Radio>
        </RadioGroup>
      )}
      <AnimatePresence mode="wait">
        {modality === "healthInsurance" && acceptsHealthInsurances && (
          <motion.div
            key="healthInsurance"
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <Autocomplete
              size="lg"
              radius="md"
              variant="bordered"
              label="Selecione o seu plano de saúde"
              onSelectionChange={(value) =>
                setSelectedHealthInsurance(value as string)
              }
              listboxProps={{
                emptyContent: "Plano de saúde não encontrado.",
              }}
              classNames={{
                clearButton: "w-12",
              }}
            >
              {healthInsurances.map((insurance: string) => (
                <AutocompleteItem
                  key={insurance}
                  value={insurance}
                  color="secondary"
                  variant="flat"
                >
                  {insurance}
                </AutocompleteItem>
              ))}
            </Autocomplete>
          </motion.div>
        )}

        {modality === "private" && (
          <motion.div
            key="private"
            variants={contentVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <Select
              label="Método de Pagamento"
              variant="bordered"
              size="lg"
              radius="md"
              selectedKeys={
                selectedPaymentMethod ? [selectedPaymentMethod] : []
              }
              onSelectionChange={(keys) => {
                const selected = Array.from(keys)[0] as PaymentMethod;
                setSelectedPaymentMethod(selected);
              }}
              classNames={{
                label: "group-data-[filled=true]:-translate-y-2",
                trigger: "min-h-16",
              }}
              renderValue={(items) => {
                return items.map((item) => (
                  <div key={item.key} className="flex items-center gap-2">
                    <PaymentMethodIcon method={item.key as PaymentMethod} />
                    <span>{item.textValue}</span>
                  </div>
                ));
              }}
            >
              {paymentMethods.map((method) => (
                <SelectItem
                  key={method}
                  textValue={PAYMENT_METHOD_LABELS[method]}
                  color="secondary"
                  variant="flat"
                >
                  <div className="flex gap-2 items-center">
                    <PaymentMethodIcon method={method} />
                    <span>{PAYMENT_METHOD_LABELS[method]}</span>
                  </div>
                </SelectItem>
              ))}
            </Select>
          </motion.div>
        )}
      </AnimatePresence>
      {!acceptsHealthInsurances && (
        <span className="px-0.5 text-sm leading-none pb-1">
          {schedules?.services
            ? "* Este serviço não aceita planos de saúde"
            : "* Este Exame não aceita planos de saúde"}
        </span>
      )}
    </motion.div>
  );
};
