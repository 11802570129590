export const BookingInfo = ({
  icon: Icon,
  text,
  bgColor,
  textColor,
  className,
}: {
  icon: any;
  text: string;
  bgColor: string;
  textColor: string;
  className?: string;
}) => (
  <div
    className={`flex items-center justify-start gap-1.5 p-2 rounded-medium ${bgColor} ${className}`}
  >
    <Icon className={`${textColor} w-4 stroke-[3]`} />
    <span className={`text-sm ${textColor}`}>{text}</span>
  </div>
);
