import React from "react";
import { Return } from "../../Molecules/Return";

export const BookingTemplate = ({
  title,
  header,
  children,
}: {
  title: string;
  header: React.ReactNode;
  children: React.ReactNode;
}) => (
  <main className="flex flex-col justify-start gap-8 items-center min-h-svh p-4 pb-20 w-full">
    <Return route="/" title={title} />
    <div className="flex flex-col w-full gap-4 justify-center items-center">
      {header}
      {children}
    </div>
  </main>
);
