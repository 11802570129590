import { Avatar, Button, Card, Chip } from "@nextui-org/react";
import { MapPin, Hospital, Phone, Star } from "lucide-react";
import { ProviderInformation } from "../../../models/ProviderInformation";

export const ProviderCard = ({
  provider,
  onCardClick,
}: {
  provider: ProviderInformation;
  onCardClick: () => void;
}) => {
  return (
    <>
      <Card
        className="flex flex-col gap-3 bg-white rounded-large p-2 min-w-80 max-w-xl max-md:w-full cursor-pointer"
        shadow="none"
        isPressable
        tabIndex={-1}
        onPress={onCardClick}
      >
        <div className="flex justify-between flex-wrap gap-2 w-full">
          <div className="flex gap-2">
            <Avatar
              src={provider.imageUrl || ""}
              radius="md"
              className="h-16 w-16"
            />
            <div className="flex flex-col mt-1">
              <span className="text-sm font-medium text-default-900">
                {provider.name}
              </span>
              <span className="text-sm font-medium text-default-400">
                {provider.crmCode}
              </span>
            </div>
          </div>
          <Chip
            variant="flat"
            color="warning"
            startContent={<Star className="w-4 ml-1 stroke-[3]" />}
            className="flex-grow"
          >
            {provider.averageRating || "?"}
          </Chip>
        </div>

        <div className="flex flex-col gap-1 px-0.5 w-full">
          <span className="flex gap-1">
            <MapPin className="w-4" />
            {provider.relatedClinic?.address}
          </span>
          <span className="flex gap-1">
            <Hospital className="w-4" />
            {provider.relatedClinic?.name}
          </span>
          <span className="flex gap-1">
            <Phone className="w-4" />
            {provider.relatedClinic?.phoneNumber}
          </span>
        </div>

        <Button
          radius="md"
          variant="flat"
          color="primary"
          className="font-semibold w-full"
          onPress={onCardClick}
        >
          Ver detalhes
        </Button>
      </Card>
    </>
  );
};
