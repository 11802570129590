export const GradientIcon = ({ className = "" }) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 225 225"
    className={className}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0"
        y1="112.5"
        x2="225"
        y2="112.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2C92E5" />
        <stop offset="1" stopColor="#882BE6" />
      </linearGradient>
    </defs>
    <g id="Layer_1-2" data-name="Layer_1">
      <path
        fill="url(#linear-gradient)"
        d="M225,65.9C225,29.5,195.5,0,159.1,0h0c-36.19,0-65.56,29.18-65.88,65.3h67.69c7.78,0,14.09,6.31,14.09,14.09,0,3.43-1.23,6.57-3.26,9.01-.4.54-.83,1.06-1.32,1.55l-57.17,57.06h47.66c7.78,0,14.09,6.31,14.09,14.09s-6.31,14.09-14.09,14.09h-81.81c-7.78,0-14.09-6.31-14.09-14.09,0-4.12,1.78-7.82,4.6-10.39l57.34-57.23h-48.96c-.19,0-.38-.02-.56-.03h-11.64C29.45,93.46,0,122.91,0,159.23h0c0,36.32,29.45,65.77,65.77,65.77h93.46c36.32,0,65.77-29.45,65.77-65.77h0c0-.1,0-.19,0-.29,0-.1,0-.19,0-.29v-92.76Z"
      />
    </g>
  </svg>
);
