export const BookingForm = ({
  children,
  onSubmit,
  onKeyDown,
}: {
  children: React.ReactNode;
  onSubmit: (e: any) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLFormElement>) => void;
}) => (
  <form
    onSubmit={onSubmit}
    onKeyDown={onKeyDown}
    className="flex flex-col h-full justify-between w-full gap-2 bg-white rounded-large p-2  overflow-x-hidden"
  >
    {children}
  </form>
);
