import { Loading } from "../components/Pages/Loading";
import { useAuth } from "../contexts/auth.context";

import { AppRoutes } from "./app.routes";
import { AuthRoutes } from "./auth.routes";

export const ApplicationRoutes = () => {
  const { signed, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className="min-h-svh w-full"
      style={{
        backgroundImage: 'url("/images/light-pattern.png")',
        backgroundRepeat: "repeat",
        backgroundSize: "400px 400px",
      }}
    >
      <div className="absolute inset-0 bg-neutral-100 bg-opacity-40"></div>
      <div className="relative z-10 flex flex-col justify-start gap-4 items-start w-full">
        {signed ? <AppRoutes /> : <AuthRoutes />}
      </div>
    </div>
  );
};
