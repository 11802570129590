export const formatCurrency = (value: string, threshold?: number) => {
  const numericValue = value.replace(/\D/g, "");
  const formattedValue = (
    threshold ? Number(numericValue) / threshold : Number(numericValue) / 100
  ).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return formattedValue;
};

export const parseCurrency = (value: string): number => {
  const numericValue = value.replace(/\D/g, "");
  return Number(numericValue) / 100;
};
