import React, { useCallback, useState } from "react";
import { Modal, ModalBody, ModalContent, ModalHeader } from "@nextui-org/react";
import { MultiStepBookingForm } from "../../Organisms/MultiStepBookingForm";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { Clinic } from "../../../models/Clinic";
import { AuthContextUserProps } from "../../../contexts/auth.context";
import { Dayjs } from "dayjs";
import { DateUtils } from "../../../utils/dateUtils";

interface BookingModalProps {
  isOpen: boolean;
  onClose: () => void;
  isClinicBooking: boolean;
  formData: any;
  onFormDataChange: (data: any) => void;
  providerDetails?: ProviderInformation;
  clinicDetails?: Clinic;
  currentUser: AuthContextUserProps;
  scheduleAppointment: {
    isPending: boolean;
    mutate: (data: any) => void;
  };
  appointmentObjectives?: { key: string; value: string }[];
  providersList?: ProviderInformation[];
  offeredExams?: Record<string, any>;
}

export const BookingModal: React.FC<BookingModalProps> = ({
  isOpen,
  onClose,
  isClinicBooking,
  formData,
  onFormDataChange,
  ...formProps
}) => {
  const modalTitle = isClinicBooking ? "Agendar Exame" : "Agendar Consulta";
  const [appointmentsWindow, setAppointmentsWindow] = useState({
    start: DateUtils.now("UTC"),
    end: DateUtils.now("UTC").add(15, "day"),
  });

  const handleDateChange = useCallback(
    (newDate: Dayjs) => {
      if (newDate.isAfter(appointmentsWindow.end, "day")) {
        setAppointmentsWindow({
          start: newDate,
          end: newDate.add(15, "day"),
        });
      } else if (newDate.isBefore(appointmentsWindow.start, "day")) {
        setAppointmentsWindow({
          start: newDate.subtract(15, "day"),
          end: newDate,
        });
      }
    },
    [appointmentsWindow]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      placement="bottom-center"
      classNames={{
        base: "max-md:h-[75vh] mx-2 my-2",
        backdrop: "bg-black/75",
        wrapper: "items-end",
        body: "p-0",
      }}
    >
      <ModalContent>
        <>
          <ModalHeader className="flex flex-col gap-1 px-3 py-3">
            {modalTitle}
          </ModalHeader>
          <ModalBody className="h-full">
            <MultiStepBookingForm
              isClinicBooking={isClinicBooking}
              formData={formData}
              onFormDataChange={onFormDataChange}
              handleDateChange={handleDateChange}
              appointmentsWindow={appointmentsWindow}
              {...formProps}
            />
          </ModalBody>
        </>
      </ModalContent>
    </Modal>
  );
};
