import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Progress,
} from "@nextui-org/react";
import { motion } from "framer-motion";
import { RefreshCw, Timer } from "lucide-react";

interface ResendCodeButtonProps {
  pulse: {
    animate: {
      scale: number[];
      transition: {
        duration: number;
        repeat: number;
        ease: string;
      };
    };
  };
  handleResendCode: () => Promise<void>;
  resendTimeout: number;
}

const formatTimeRemaining = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export const ResendCodeButton = ({
  resendTimeout,
  handleResendCode,
  pulse,
}: ResendCodeButtonProps) => {
  const progressPercentage = (resendTimeout / 300) * 100;

  return (
    <Popover placement="top-end" radius="lg" showArrow>
      <PopoverTrigger>
        <Button isIconOnly variant="light">
          <motion.div animate={resendTimeout === 0 ? pulse.animate : {}}>
            <RefreshCw
              size={16}
              className={resendTimeout > 0 ? "opacity-50" : ""}
            />
          </motion.div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="p-2 w-64"
        >
          <div className="flex items-center gap-2 mb-2">
            <Timer size={16} className="text-default-400" />
            <span className="text-sm font-medium">
              {resendTimeout > 0 ? "Aguarde para reenviar" : "Reenviar código"}
            </span>
          </div>

          {resendTimeout > 0 ? (
            <div className="space-y-2">
              <div className="text-sm text-default-600">
                Tempo restante: {formatTimeRemaining(resendTimeout)}
              </div>

              <Progress size="md" value={progressPercentage} />
            </div>
          ) : (
            <div className="space-y-2">
              <p className="text-sm text-default-600">
                Clique para reenviar um novo código de verificação.
              </p>
              <Button
                size="sm"
                color="primary"
                className="w-full"
                onPress={handleResendCode}
                startContent={<RefreshCw size={14} />}
                radius="md"
              >
                Reenviar agora
              </Button>
            </div>
          )}
        </motion.div>
      </PopoverContent>
    </Popover>
  );
};
