import React, { useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { ShieldCheck, SendHorizontal } from "lucide-react";
import { useAuth } from "../../../contexts/auth.context";
import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import { ResendCodeButton } from "../../Atoms/ResendCodeButton";
import { OTPInput, PhoneNumberInput } from "../../Molecules/Inputs";

interface PhoneVerificationModalProps {
  isOpen: boolean;
  onOpenChange: () => void;
}

const pulse = {
  animate: {
    scale: [1, 1.05, 1],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
};

const inputTransition = {
  type: "spring",
  stiffness: 300,
  damping: 25,
};

const inputVariants = {
  hidden: {
    opacity: 0,
    y: 20,
    scale: 0.95,
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: inputTransition,
  },
  exit: {
    opacity: 0,
    y: -20,
    scale: 0.95,
    transition: { duration: 0.2 },
  },
};

const modalVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut",
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    transition: { duration: 0.2, ease: "easeIn" },
  },
};

const headerIconVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3, ease: "easeOut" },
  },
};

const headerTextVariants = {
  hidden: { opacity: 0, x: 20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3, ease: "easeOut" },
  },
};

const contentVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.3, ease: "easeOut" },
  },
};

const successAnimation = {
  scale: [1, 1.2, 1],
  rotate: [0, 360, 360],
  transition: {
    duration: 0.8,
    ease: "easeInOut",
    delay: 0.07,
  },
};

export const PhoneVerificationModal: React.FC<PhoneVerificationModalProps> = ({
  isOpen,
  onOpenChange,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [resendTimeout, setResendTimeout] = useState<number>(0);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const { handleSendPhoneVerificationCode, handleVerifyPhoneCode } = useAuth();

  const validatePhoneNumber = (value: string) =>
    value.length >= 10 && value.length <= 11;

  const isInvalid = useMemo(() => {
    return phoneNumber !== "" && !validatePhoneNumber(phoneNumber);
  }, [phoneNumber]);

  const startResendTimeout = () => {
    setResendTimeout(300);
    const timer = setInterval(() => {
      setResendTimeout((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleSendVerificationCode = async () => {
    setIsLoading(true);
    try {
      await handleSendPhoneVerificationCode(phoneNumber);
      setIsCodeSent(true);
      startResendTimeout();
      toast.success("Código de verificação enviado com sucesso!");
    } catch (error: any) {
      const errorMessage =
        error?.message || "Ocorreu um erro ao enviar o código de verificação.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    if (resendTimeout > 0) return;
    setVerificationCode("");
    await handleSendVerificationCode();
  };

  const handleVerifyCode = async () => {
    setIsLoading(true);
    try {
      await handleVerifyPhoneCode(phoneNumber, verificationCode);

      setShowSuccess(true);

      // Success animation before closing
      await new Promise((resolve) => setTimeout(resolve, 1500));

      toast.success("Número de telefone verificado com sucesso!");
      handleClose();
    } catch (error: any) {
      const errorMessage =
        error?.message ||
        "Erro ao verificar o código. Por favor, tente novamente.";
      toast.error(errorMessage);

      if (
        errorMessage.includes("código expirou") ||
        errorMessage.includes("máximo de tentativas excedido")
      ) {
        setIsCodeSent(false);
      }

      setVerificationCode("");
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setPhoneNumber("");
    setVerificationCode("");
    setIsCodeSent(false);
    setResendTimeout(0);
    setIsPopoverOpen(false);
  };

  const handleClose = () => {
    handleReset();
    onOpenChange();
  };

  const hasUnsavedData = phoneNumber || verificationCode;

  return (
    <Modal
      size="lg"
      backdrop="blur"
      isOpen={isOpen}
      placement="top-center"
      onOpenChange={onOpenChange}
      classNames={{
        backdrop: "bg-black/75",
        base: "overflow-hidden",
      }}
    >
      <ModalContent>
        {(_) => (
          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className="overflow-hidden"
          >
            <ModalHeader className="flex flex-col px-4 gap-1">
              <motion.div className="flex items-center gap-2">
                <motion.div variants={headerIconVariants}>
                  <ShieldCheck
                    className={showSuccess ? "text-success" : "text-primary"}
                  />
                </motion.div>
                <motion.span variants={headerTextVariants}>
                  Verifique o seu telefone
                </motion.span>
              </motion.div>
            </ModalHeader>

            <ModalBody className="py-0 px-4">
              <motion.div
                variants={contentVariants}
                className="space-y-4 relative overflow-hidden"
              >
                <motion.span
                  className="text-default-600 block"
                  variants={contentVariants}
                >
                  Um SMS será enviado para o número informado. Digite o código
                  recebido na próxima etapa para confirmar o número de celular.
                </motion.span>
                <motion.div
                  className="flex w-full gap-2 justify-between items-center"
                  variants={inputVariants}
                >
                  <PhoneNumberInput
                    size="lg"
                    label="Telefone"
                    variant="bordered"
                    isInvalid={isInvalid}
                    isDisabled={isCodeSent}
                    color={isInvalid ? "danger" : "default"}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    errorMessage="Preencha um número de telefone válido"
                    startContent={
                      <div className="pointer-events-none flex items-center">
                        <span className="text-default-400 text-sm">+55</span>
                      </div>
                    }
                    classNames={{
                      input: "text-default-700",
                      helperWrapper: "group-data-[has-helper=true]:p-0",
                    }}
                    isRequired
                  />

                  <AnimatePresence mode="wait">
                    {isCodeSent && (
                      <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={inputTransition}
                      >
                        <ResendCodeButton
                          handleResendCode={handleResendCode}
                          pulse={pulse}
                          resendTimeout={resendTimeout}
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
                <AnimatePresence mode="wait">
                  {isCodeSent && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 25,
                      }}
                    >
                      <OTPInput
                        verificationCode={verificationCode}
                        setVerificationCode={setVerificationCode}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>{" "}
              </motion.div>
            </ModalBody>

            <ModalFooter className="gap-4 px-4">
              <AnimatePresence>
                {showSuccess && (
                  <motion.div
                    initial={{ scale: 1, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 1, opacity: 0 }}
                    className="absolute inset-0 overflow-hidden flex items-center justify-center bg-success/10 backdrop-blur-[8px] z-20"
                  >
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={successAnimation}
                      className="flex items-center justify-center"
                    >
                      <ShieldCheck className="text-success" size={48} />
                    </motion.div>
                  </motion.div>
                )}
              </AnimatePresence>
              <Popover
                placement="top"
                showArrow={true}
                isOpen={isPopoverOpen}
                onOpenChange={(open) => setIsPopoverOpen(open)}
              >
                <PopoverTrigger>
                  <Button
                    color="danger"
                    variant="light"
                    isDisabled={!hasUnsavedData}
                  >
                    Cancelar
                  </Button>
                </PopoverTrigger>
                {hasUnsavedData && (
                  <PopoverContent className="p-2">
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                    >
                      <span className="text-sm font-medium mb-2">
                        Tem certeza que deseja cancelar?
                      </span>
                      <p className="text-sm text-gray-500 mb-4">
                        Todo o progresso será perdido.
                      </p>
                      <div className="flex justify-end w-full gap-2">
                        <Button
                          size="sm"
                          radius="md"
                          variant="light"
                          onPress={() => setIsPopoverOpen(!isPopoverOpen)}
                        >
                          Voltar
                        </Button>
                        <Button
                          size="sm"
                          radius="md"
                          color="danger"
                          onPress={handleClose}
                        >
                          Confirmar
                        </Button>
                      </div>
                    </motion.div>
                  </PopoverContent>
                )}
              </Popover>

              <AnimatePresence mode="wait">
                {!isCodeSent ? (
                  <motion.div
                    key="send"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                  >
                    <Button
                      color={showSuccess ? "success" : "primary"}
                      onPress={handleSendVerificationCode}
                      isLoading={isLoading}
                      isDisabled={isInvalid || !phoneNumber || isLoading}
                      startContent={!isLoading && <SendHorizontal size={16} />}
                    >
                      Enviar SMS
                    </Button>
                  </motion.div>
                ) : (
                  <motion.div
                    key="verify"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                  >
                    <Button
                      color="primary"
                      onPress={handleVerifyCode}
                      isLoading={isLoading}
                      isDisabled={
                        !verificationCode ||
                        verificationCode.length !== 6 ||
                        isLoading
                      }
                      startContent={!isLoading && <ShieldCheck size={16} />}
                    >
                      Verificar Código
                    </Button>
                  </motion.div>
                )}
              </AnimatePresence>
            </ModalFooter>
          </motion.div>
        )}
      </ModalContent>
    </Modal>
  );
};
