import React, { useState, useEffect, useMemo, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Button,
  Progress,
  Autocomplete,
  AutocompleteItem,
} from "@nextui-org/react";
import { ServiceSelection } from "../../Atoms/ServiceSelection";
import { ExamSelection } from "../../Atoms/ExamSelection";
import { ModalitySelector } from "../../Molecules/ModalitySelector";
import { ObjectiveSelector } from "../../Organisms/ObjectiveSelector";
import { AppointmentScheduler } from "../../Organisms/AppointmentScheduler";
import {
  PAYMENT_METHOD_LABELS,
  PaymentMethod,
} from "../../../models/PaymentMethod";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { Clinic } from "../../../models/Clinic";
import { DateUtils } from "../../../utils/dateUtils";
import { calculateAvailableTimesPerDay } from "../../FormModalLogic/utils";
import { AuthContextUserProps } from "../../../contexts/auth.context";
import { Dayjs } from "dayjs";
import { BookingForm } from "../BookingForm";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useAppointmentsQuery } from "../../../hooks/queries/useAppointmentsQuery";
import { AppointmentStatus } from "../../../models/Appointment";

interface FormData {
  selectedService: string | null;
  selectedExam: string | null;
  modality: "private" | "healthInsurance";
  selectedHealthInsurance: string | null;
  selectedPaymentMethod: PaymentMethod | null;
  reason: string | null;
  selectedOptions: string[];
  selectedProvider: ProviderInformation | null;
}

interface MultiStepBookingFormProps {
  isClinicBooking: boolean;
  providerDetails?: ProviderInformation;
  clinicDetails?: Clinic;
  currentUser: AuthContextUserProps;
  scheduleAppointment: {
    isPending: boolean;
    mutate: (data: any) => void;
  };
  appointmentObjectives?: { key: string; value: string }[];
  providersList?: ProviderInformation[];
  offeredExams?: Record<string, any>;
  formData: any;
  onFormDataChange: (data: any) => void;
  handleDateChange: (newDate: Dayjs) => void;
  appointmentsWindow: {
    start: Dayjs;
    end: Dayjs;
  };
}

export const MultiStepBookingForm: React.FC<MultiStepBookingFormProps> = ({
  isClinicBooking,
  providerDetails,
  clinicDetails,
  currentUser,
  scheduleAppointment,
  appointmentObjectives,
  providersList,
  offeredExams,
  appointmentsWindow,
  formData: initialFormData,
  onFormDataChange,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [direction, setDirection] = useState(0);
  const [formData, setFormData] = useState<FormData>(
    initialFormData || {
      selectedService: null,
      selectedExam: null,
      modality: "private",
      selectedHealthInsurance: null,
      selectedPaymentMethod: null,
      reason: null,
      selectedOptions: [],
      selectedProvider: null,
    }
  );
  const [isStepValid, setIsStepValid] = useState(false);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [isManualNavigation, setIsManualNavigation] = useState(false);

  const autoAdvanceTimerRef = React.useRef<NodeJS.Timeout | null>(null);

  const { data: appointments } = useAppointmentsQuery({
    clinicId: isClinicBooking ? clinicDetails?.id : undefined,
    providerId: isClinicBooking
      ? formData.selectedProvider?.id
      : providerDetails?.id,
    start: appointmentsWindow.start,
    end: appointmentsWindow.end,
  });

  const conflictingAppointmentsSet = useMemo(() => {
    const set = new Set<string>();
    appointments?.forEach((appointment) => {
      if (appointment.status === AppointmentStatus.CONFIRMED) {
        // For confirmed appointments, only disable the selectedOption
        if (appointment.selectedOption) {
          set.add(appointment.selectedOption);
        }
      } else if (appointment.status === AppointmentStatus.WAITING_PROVIDER) {
        // For waiting provider status, disable all options
        [appointment.option1, appointment.option2, appointment.option3]
          .filter(Boolean)
          .forEach((option) => set.add(option as string));
      }
    });
    return set;
  }, [appointments]);

  const isProviderExam =
    isClinicBooking && providersList && providersList.length > 0;

  const scheduleToUse = useMemo(() => {
    if (isClinicBooking) {
      return isProviderExam && formData.selectedProvider
        ? formData.selectedProvider.schedules.exams
        : clinicDetails?.schedules?.exams;
    } else {
      return providerDetails?.schedules.appointments;
    }
  }, [
    isClinicBooking,
    isProviderExam,
    formData.selectedProvider,
    clinicDetails,
    providerDetails,
  ]);

  const acceptedHealthInsurances = useMemo(() => {
    if (isClinicBooking) {
      if (
        !formData.selectedExam ||
        (!clinicDetails && !formData.selectedProvider)
      )
        return [];

      const examData =
        isProviderExam && formData.selectedProvider
          ? formData.selectedProvider.schedules.exams?.exams?.[
              formData.selectedExam
            ]
          : clinicDetails?.schedules?.exams?.exams?.[formData.selectedExam];

      if (examData?.acceptsHealthInsurances) {
        return (
          examData.acceptedHealthInsurances ||
          (isProviderExam
            ? formData.selectedProvider?.acceptedHealthInsurances
            : clinicDetails?.acceptedHealthInsurances) ||
          []
        );
      }
      return [];
    } else {
      return providerDetails?.acceptedHealthInsurances || [];
    }
  }, [
    isClinicBooking,
    formData.selectedExam,
    clinicDetails,
    formData.selectedProvider,
    isProviderExam,
    providerDetails,
  ]);

  const availableTimes = useMemo(() => {
    if (
      isClinicBooking &&
      (!clinicDetails || (isProviderExam && !formData.selectedProvider))
    )
      return;
    if (!isClinicBooking && !providerDetails) return;

    const timezone = isClinicBooking
      ? isProviderExam
        ? formData.selectedProvider?.timezone
        : clinicDetails?.timezone
      : providerDetails?.timezone;

    if (!scheduleToUse || !timezone) return;

    return calculateAvailableTimesPerDay(
      timezone,
      scheduleToUse,
      isClinicBooking,
      isClinicBooking
        ? (formData.selectedExam as string)
        : (formData.selectedService as string),
      undefined,
      conflictingAppointmentsSet
    );
  }, [
    isClinicBooking,
    clinicDetails,
    isProviderExam,
    formData.selectedProvider,
    providerDetails,
    scheduleToUse,
    formData.selectedExam,
    formData.selectedService,
    conflictingAppointmentsSet,
  ]);

  const isTimeDisabled = (day: Dayjs, time: string) => {
    const timezone = isClinicBooking
      ? isProviderExam
        ? formData.selectedProvider?.timezone
        : clinicDetails?.timezone
      : providerDetails?.timezone;

    if (!timezone) return true;

    const timeDate = day
      .tz(timezone)
      .hour(parseInt(time.split(":")[0]))
      .minute(parseInt(time.split(":")[1]));

    if (timeDate.isBefore(DateUtils.now(timezone))) {
      return true;
    }

    const dayOfWeek = timeDate.day();
    if (scheduleToUse?.disabledTimes?.[dayOfWeek]?.includes(time)) {
      return true;
    }

    const utcTimeString = DateUtils.toDBFormat(timeDate, timezone);
    return utcTimeString ? conflictingAppointmentsSet.has(utcTimeString) : true;
  };

  const steps = [
    ...(isClinicBooking
      ? [
          {
            component: (
              <ExamSelection
                selectedExam={formData.selectedExam}
                offeredExams={offeredExams}
                onExamChange={(examId) => {
                  if (typeof examId === "string") {
                    setFormData({
                      ...formData,
                      selectedExam: examId,
                      selectedProvider: null,
                    });
                    setIsStepValid(!!examId);
                    setIsManualNavigation(false);
                  }
                }}
              />
            ),
            isValid: () => !!formData.selectedExam,
          },
        ]
      : [
          {
            component: (
              <ServiceSelection
                selectedService={formData.selectedService}
                services={providerDetails?.schedules.appointments?.services}
                onServiceChange={(service) => {
                  setFormData({ ...formData, selectedService: service });
                  setIsStepValid(!!service);
                  setIsManualNavigation(false);
                }}
              />
            ),
            isValid: () => !!formData.selectedService,
          },
        ]),
    ...(isClinicBooking && isProviderExam
      ? [
          {
            component: (
              <div className="flex flex-col w-full gap-2 bg-white rounded-large">
                <div className="w-full bg-default-100 rounded-medium p-2">
                  <h2 className="text-default-700 font-semibold text-sm">
                    Profissional Responsável
                  </h2>
                </div>
                <Autocomplete
                  size="lg"
                  radius="md"
                  variant="bordered"
                  label="Selecione o profissional"
                  selectedKey={formData.selectedProvider?.id}
                  onSelectionChange={(value) => {
                    const provider = providersList?.find(
                      (provider) => provider.id === value
                    );
                    setFormData({
                      ...formData,
                      selectedProvider: provider || null,
                    });
                    setIsStepValid(!!provider);
                    setIsManualNavigation(false);
                  }}
                >
                  {providersList?.map((provider) => (
                    <AutocompleteItem
                      key={provider.id as string}
                      value={provider.id}
                      color="secondary"
                      variant="flat"
                    >
                      {provider.name}
                    </AutocompleteItem>
                  ))}
                </Autocomplete>
              </div>
            ),
            isValid: () => !!formData.selectedProvider,
          },
        ]
      : []),
    {
      component: (
        <div className="flex flex-col w-full gap-2 bg-white rounded-large">
          <div className="w-full bg-default-100 rounded-medium p-2">
            <h2 className="text-default-700 font-semibold text-sm">
              Modalidade
            </h2>
          </div>
          <ModalitySelector
            modality={formData.modality}
            setModality={(modality: "private" | "healthInsurance") => {
              setFormData({ ...formData, modality });
              setIsStepValid(
                modality === "private" || !!formData.selectedHealthInsurance
              );
            }}
            healthInsurances={acceptedHealthInsurances}
            paymentMethods={
              (isClinicBooking
                ? isProviderExam && formData.selectedProvider
                  ? formData.selectedProvider.acceptedPaymentMethods
                  : clinicDetails?.acceptedPaymentMethods
                : providerDetails?.acceptedPaymentMethods) as PaymentMethod[]
            }
            setSelectedHealthInsurance={(insurance) => {
              setFormData({
                ...formData,
                selectedHealthInsurance: insurance as string,
              });
              setIsStepValid(!!insurance);
              setIsManualNavigation(false);
            }}
            selectedPaymentMethod={formData.selectedPaymentMethod}
            setSelectedPaymentMethod={(method) => {
              setFormData({
                ...formData,
                selectedPaymentMethod: method as PaymentMethod,
              });
              setIsStepValid(!!method);
              setIsManualNavigation(false);
            }}
            selectedMedicalService={
              isClinicBooking ? formData.selectedExam : formData.selectedService
            }
            schedules={scheduleToUse}
          />
        </div>
      ),
      isValid: () =>
        (formData.modality === "private" && !!formData.selectedPaymentMethod) ||
        (formData.modality === "healthInsurance" &&
          !!formData.selectedHealthInsurance),
    },
    ...(!isClinicBooking && appointmentObjectives
      ? [
          {
            title: "Selecione o Objetivo",
            component: (
              <div className="flex flex-col w-full gap-2 bg-white rounded-large">
                <div className="w-full bg-default-100 rounded-medium p-2">
                  <h2 className="text-default-700 font-semibold text-sm">
                    Objetivo do Agendamento
                  </h2>
                </div>
                <ObjectiveSelector
                  objectives={appointmentObjectives}
                  onObjectiveSelect={(reason) => {
                    setFormData({ ...formData, reason });
                    setIsStepValid(!!reason);
                    setIsManualNavigation(false);
                  }}
                />
              </div>
            ),
            isValid: () => true,
            condition: () =>
              !!providerDetails?.schedules.appointments?.services?.[
                formData.selectedService as string
              ]?.acceptsObjectiveSelection,
          },
        ]
      : []),
    {
      component: (
        <AppointmentScheduler
          availableTimes={availableTimes as string[]}
          onTimeSelection={(day, time) => {
            const selectedTime = DateUtils.toDBFormat(
              day
                .hour(parseInt(time.split(":")[0]))
                .minute(parseInt(time.split(":")[1])),
              isClinicBooking
                ? isProviderExam
                  ? formData.selectedProvider?.timezone
                  : clinicDetails?.timezone
                : providerDetails?.timezone
            );
            if (selectedTime) {
              const newSelectedOptions = formData.selectedOptions.includes(
                selectedTime
              )
                ? formData.selectedOptions.filter((t) => t !== selectedTime)
                : [...formData.selectedOptions, selectedTime].slice(-3);

              setFormData((prev) => ({
                ...prev,
                selectedOptions: newSelectedOptions,
              }));
              setIsStepValid(newSelectedOptions.length > 0);
            }
          }}
          selectedOptions={formData.selectedOptions}
          isDayDisabled={(day) => {
            const dayOfWeek = day.day();
            return !!scheduleToUse?.disabledDays?.includes(dayOfWeek);
          }}
          isTimeDisabled={isTimeDisabled}
          providerTimezone={
            (isClinicBooking
              ? isProviderExam
                ? formData.selectedProvider?.timezone
                : clinicDetails?.timezone
              : providerDetails?.timezone) as string
          }
          userTimezone={currentUser.timezone}
          medicalServiceDuration={
            (isClinicBooking
              ? isProviderExam
                ? formData.selectedProvider?.schedules.exams?.exams?.[
                    formData.selectedExam as string
                  ]?.duration
                : clinicDetails?.schedules?.exams?.exams?.[
                    formData.selectedExam as string
                  ]?.duration
              : providerDetails?.schedules.appointments?.services?.[
                  formData.selectedService as string
                ]?.duration) as number
          }
          isExam={isClinicBooking}
        />
      ),
      isValid: () => formData.selectedOptions.length > 0,
    },
  ].filter((step) => !step.condition || step.condition());

  useEffect(() => {
    setIsStepValid(steps[currentStep].isValid());
    // If we're on the last step and it's valid, we're ready to submit
    if (currentStep === steps.length - 1 && steps[currentStep].isValid()) {
      setIsReadyToSubmit(true);
    } else {
      setIsReadyToSubmit(false);
    }
  }, [currentStep, formData, steps]);

  useEffect(() => {
    onFormDataChange(formData);
  }, [formData, onFormDataChange]);

  const nextStep = useCallback(() => {
    if (currentStep < steps.length - 1 && isStepValid) {
      setDirection(1);
      setCurrentStep((prevStep) => {
        const newStep = prevStep + 1;
        // Reset step validation for the new step
        setIsStepValid(steps[newStep].isValid());
        return newStep;
      });
    }
  }, [currentStep, isStepValid, steps]);

  const prevStep = useCallback(() => {
    if (currentStep > 0) {
      setIsManualNavigation(true);
      setDirection(-1);
      setCurrentStep(currentStep - 1);

      // Clear any pending auto-advance
      if (autoAdvanceTimerRef.current) {
        clearTimeout(autoAdvanceTimerRef.current);
      }
    }
  }, [currentStep]);

  // Enhanced effect to handle auto-advancement
  useEffect(() => {
    const handleStepValidation = () => {
      const isLastStep = currentStep === steps.length - 1;
      const shouldAutoAdvance =
        isStepValid && !isLastStep && !isManualNavigation;

      // Clear any existing timer
      if (autoAdvanceTimerRef.current) {
        clearTimeout(autoAdvanceTimerRef.current);
      }

      if (shouldAutoAdvance) {
        autoAdvanceTimerRef.current = setTimeout(() => {
          nextStep();
          // Reset manual navigation flag after auto-advance
          setIsManualNavigation(false);
        }, 500);
      }

      // Update submission readiness for the last step
      if (isLastStep && isStepValid) {
        setIsReadyToSubmit(true);
      } else {
        setIsReadyToSubmit(false);
      }
    };

    handleStepValidation();

    return () => {
      if (autoAdvanceTimerRef.current) {
        clearTimeout(autoAdvanceTimerRef.current);
      }
    };
  }, [isStepValid, currentStep, steps.length, nextStep, isManualNavigation]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (isReadyToSubmit) {
        if (isStepValid && currentStep === steps.length - 1) {
          isClinicBooking
            ? scheduleAppointment.mutate({
                provider: formData.selectedProvider,
                clinic: clinicDetails,
                examId: formData.selectedExam,
                selectedOptions: formData.selectedOptions,
                paymentMethod: formData.selectedPaymentMethod
                  ? PAYMENT_METHOD_LABELS[formData.selectedPaymentMethod]
                  : undefined,
                healthInsurance: formData.selectedHealthInsurance,
              })
            : scheduleAppointment.mutate({
                provider: providerDetails,
                reason: formData.reason,
                serviceId: formData.selectedService,
                selectedOptions: formData.selectedOptions,
                paymentMethod: formData.selectedPaymentMethod
                  ? PAYMENT_METHOD_LABELS[formData.selectedPaymentMethod]
                  : undefined,
                healthInsurance: formData.selectedHealthInsurance,
              });
        }
      }
    },
    [
      isReadyToSubmit,
      isStepValid,
      currentStep,
      steps.length,
      isClinicBooking,
      formData,
      scheduleAppointment,
      clinicDetails,
      providerDetails,
    ]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (currentStep < steps.length - 1) {
          nextStep();
        } else if (isStepValid) {
          handleSubmit(e as React.FormEvent);
        }
      }
    },
    [currentStep, steps.length, nextStep, isStepValid, handleSubmit]
  );

  const stepVariants = {
    enter: (direction: number) => ({
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => ({
      x: direction < 0 ? "100%" : "-100%",
      opacity: 0,
    }),
  };

  useEffect(() => {
    setIsManualNavigation(false);
  }, [formData]);

  return (
    <BookingForm onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
      <div className="flex flex-col gap-2">
        <Progress
          aria-label="Progresso do Agendamento"
          value={(currentStep + 1) * (100 / steps.length)}
          className="mb-2 pt-1 px-0.5"
        />

        <AnimatePresence initial={false} custom={direction} mode="wait">
          <motion.div
            key={currentStep}
            custom={direction}
            variants={stepVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.3 },
            }}
            className="w-full"
          >
            {steps[currentStep].component}
          </motion.div>
        </AnimatePresence>
      </div>

      <div className="flex justify-between mt-2">
        <Button
          color="primary"
          variant="light"
          onPress={prevStep}
          isDisabled={currentStep === 0}
          isIconOnly
          aria-label="Anterior"
          size="lg"
        >
          <ChevronLeft />
        </Button>
        {currentStep !== steps.length - 1 && (
          <Button
            color="primary"
            onPress={nextStep}
            isDisabled={!isStepValid}
            isIconOnly
            aria-label="Próximo"
            size="lg"
          >
            <ChevronRight />
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <Button
            color="primary"
            type="submit"
            isLoading={scheduleAppointment.isPending}
            isDisabled={!isReadyToSubmit}
            size="lg"
          >
            Agendar
          </Button>
        )}
      </div>
    </BookingForm>
  );
};
