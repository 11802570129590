import { Skeleton } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { useAppointmentDetailsQuery } from "../../../hooks/queries/useAppointmentDetailsQuery";
import { useProviderDetailsQuery } from "../../../hooks/queries/useProviderDetailsQuery";
import { AppointmentDetails } from "../../Organisms/AppointmentDetails";
import { getFormattedAppointmentDate } from "../../../utils/getFormattedAppointmentDate";
import { useAuth } from "../../../contexts/auth.context";
import { statusToTabMap } from "../../../utils/statusTabMapping";
import { AppointmentStatus } from "../../../models/Appointment";
import { useClinicDetailsQuery } from "../../../hooks/queries/useClinicDetailsQuery";
import { AppointmentHeader } from "../../Molecules/AppointmentHeader";

export const Appointment = () => {
  const { appointmentId } = useParams();
  const { currentUser } = useAuth();

  const { data: appointmentDetails, isLoading: isAppointmentLoading } =
    useAppointmentDetailsQuery(appointmentId as string);

  const { data: providerDetails, isLoading: isProviderLoading } =
    useProviderDetailsQuery(appointmentDetails?.providerId);
  const { data: clinicDetails, isLoading: isClinicLoading } =
    useClinicDetailsQuery(appointmentDetails?.clinicId);
  const { formattedDate, formattedTime } = appointmentDetails
    ? getFormattedAppointmentDate(appointmentDetails, currentUser.timezone)
    : { formattedDate: "", formattedTime: "" };

  const isLoading =
    isAppointmentLoading ||
    isClinicLoading ||
    (appointmentDetails?.providerId && isProviderLoading);

  const hasValidData = appointmentDetails && (providerDetails || clinicDetails);

  return (
    <main className="flex flex-col justify-start items-start min-h-svh p-4 gap-4 w-full">
      <AppointmentHeader
        route={`/appointments?tab=${
          statusToTabMap[appointmentDetails?.status as AppointmentStatus]
        }`}
        type={appointmentDetails?.examId ? "exam" : "appointment"}
      />

      {isLoading ? (
        <Skeleton className="min-h-96" />
      ) : hasValidData ? (
        <AppointmentDetails
          appointment={appointmentDetails}
          provider={providerDetails}
          clinic={clinicDetails}
          formattedDate={formattedDate}
          formattedTime={formattedTime}
        />
      ) : (
        <div>No appointment details available</div>
      )}
    </main>
  );
};
